<template>
    <div class="container">
        <Button class="p-button-raised p-button-rounded my-3 p-button-danger" icon="pi pi-arrow-left" @click="goBack" />
        <template v-if="loadingProgramsPerCountry">
            <div class="row">
                <div class="col-12 py-4 d-flex justify-content-center">
                    <ProgressSpinner />
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <div class="card">
                    <header class="p-3">
                        <h4 v-if="selectedTopic"> Programmes per Country for <strong> {{ selectedTopic.name }}</strong> </h4>
                    </header>
                    <main class="mx-5">
                        <div v-if="programsPerCountry.length > 0">
                            <div v-for="(pPc, pPcIndex) in programsPerCountry" :key="pPcIndex">
                                <div 
                                    v-for="(regionCountry, regionCountriesIndex ) in pPc.regionCountries"
                                    :key="regionCountriesIndex">
                                    <div v-if="regionCountry.docProgramTopicSegItems.length > 0">
                                        <h3> <strong>{{ pPc.name }}</strong> </h3>
                                        <div 
                                            v-for="(docProgramTopicSegItem, docProgramTopicSegItemIndex  ) in regionCountry.docProgramTopicSegItems"
                                            :key="docProgramTopicSegItemIndex"
                                        >
                                            <div v-if="docProgramTopicSegItem.documentProgramTopic.programSummary">
                                                <h4 
                                                    style="text-decoration: underline; cursor: pointer; width: fit-content;"
                                                    @click="openEditProgram(docProgramTopicSegItem.documentProgramTopicId)">
                                                    {{
                                                        docProgramTopicSegItem.documentProgramTopic.documentProgram.name
                                                    }}
                                                </h4>
                                                <p>
                                                    {{ docProgramTopicSegItem.documentProgramTopic.programSummary }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <h3 style="color: red;"><strong>Programmes not Found</strong></h3>
                        </div>
                    </main>
                    <footer class="col-12 py-4 mx-5">
                        <Button 
                            label="Export data" 
                            class="ml-3" 
                            style="background-color: blue;" 
                            @click="exportData()" 
                            :loading="loadingToExcel"
                        />
                    </footer>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';

import { useToast } from 'vue-toastification';

// output Service
import {exportToExcelProgramsPerCountry} from './../../../../region/services/region.service';


const toast = useToast();

export default {
    name: 'ProgramsPerCountryPage',
    components: {
        // primevue Components
        ProgressSpinner,
        Button,
    },
    setup: () => ({
        v$: useVuelidate()
    }),
    data() {
        return {
            // loading
            loadingProgramsPerCountry: true,
            loading: true,
            loadingToExcel : false
        }
    },
    validations() {
        return {
        }
    },
    async created() {
        await this.getProgramsPerCountry();

    },
    computed: {
        selectedTopic() {
            return this.$store.getters.obtainSelectedTopic;
        },
        programsPerCountry() {
            return this.$store.getters.obtainProgramsPerCountry;
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async getProgramsPerCountry() {
            try {
                this.loadingProgramsPerCountry = true;
                await this.$store.dispatch('obtainProgramPerCountry', this.selectedTopic.topicId)
                this.loadingProgramsPerCountry = false;
            } catch (error) {
                this.loadingProgramsPerCountry = false;
            }
        },
        async exportData() {
            this.loadingExportToExcel = true
            try {
                await exportToExcelProgramsPerCountry(this.selectedTopic);
                this.loadingExportToExcel = false
            } catch (error) {
                toast.error(error.message);
                this.loadingExportToExcel = false
            }
            
        },
        openEditProgram(documentProgramTopicId) {
            window.open(`./../program/overview/${documentProgramTopicId}`, '_blank')
        },
    },
    watch: {
        async selectedTopic(newValue) {
            if (newValue) {
                await this.getProgramsPerCountry();
            }
        },
    },
}
</script>

<style scoped></style>